"use strict";

angular
  .module("cpformplastApp.jobs")
  .factory(
    "SubjobSchedule",
    function (Subjob, SubjobProductionReport, Util, ItemSchedule) {
      class SubjobSchedule {
        constructor({
          id,
          machineNumber,
          startDate,
          endDate,
          subjob,
          quantity,
          assignedQuantity,
          hoursAmount,
          materialReady,
          poundsNeeded,
          startBoxIndex,
          lastBoxIndex,
          producedQuantity,
          remainingQuantity,
          remainingHoursAmount,
          productionReports,
          itemSchedule,
          isRecent,
          requiredMoldDate,
          remainingPoundsNeeded,
          poundsPerWeek,
          efficiencyReports,
          machineId,
          waitTimeBeforeNextSchedule,
          requiresOvertime,
          lastScheduleUpdate,
          scheduleUpdateIsRecent
        }) {
          this.id = id;
          this.machineNumber = machineNumber;
          this.startDate = new Date(startDate);
          this.endDate = new Date(endDate);
          this.requiredMoldDate = new Date(requiredMoldDate);
          this.subjob = new Subjob(subjob);
          this.quantity = Math.ceil(quantity);
          this.hoursAmount = Math.ceil(hoursAmount);
          this.poundsNeeded = Math.ceil(poundsNeeded);
          this.producedQuantity = Math.ceil(producedQuantity);
          this.remainingQuantity = Math.ceil(remainingQuantity);
          this.poundsPerWeek = Math.ceil(poundsPerWeek).toLocaleString();
          this.startBoxIndex = startBoxIndex;
          this.lastBoxIndex = lastBoxIndex;
          this.productionReports = productionReports
            ? productionReports.map((o) => new SubjobProductionReport(o))
            : [];
          this.quantityChanged = false;
          this.waitTimeBeforeNextSchedule = waitTimeBeforeNextSchedule;
          this.isDragged = false;
          this.materialReady = materialReady;
          this.requiresOvertime = requiresOvertime;
          this.remainingHoursAmount = Math.ceil(remainingHoursAmount);
          this.remainingPoundsNeeded = Math.ceil(remainingPoundsNeeded);
          this.isRecent = isRecent;
          this.itemSchedule = new ItemSchedule(itemSchedule);
          this.efficiencyReports = efficiencyReports;
          this.machineId = machineId;
          this.sheetsPerHour =
            this.efficiencyReports && this.efficiencyReports.length > 0
              ? this.efficiencyReports[this.efficiencyReports.length - 1]
                  .sheetsPerHour
              : this.subjob.job.item.sheetsPerHour;
          this.lastScheduleUpdate = lastScheduleUpdate;
          this.scheduleUpdateIsRecent = scheduleUpdateIsRecent;
        }

        getValueFromKey(key) {
          switch (key) {
            case "machine":
              return this.machineNumber;
            case "item":
              return this.subjob.job.item.name;
            case "setup":
              return this.subjob.job.item.setup;
            case "ups":
              return this.subjob.job.item.ups;
            case "material":
              return (
                this.subjob.job.item.category.dimension +
                " " +
                this.subjob.job.item.category.name
              );
            case "deliveryDate":
              return Util.getDateString(this.subjob.date);
            case "sheetsPerHour":
              return this.sheetsPerHour;
            case "requiredDate":
              return Util.getDateString(this.requiredMoldDate);
            case "start":
              return Util.getDateString(this.startDate);
            case "salesman":
              return this.subjob.job.item.client.salesman.name;
            case "unitsPerDay":
              return Number(this.subjob.job.item.unitsPerDay).toLocaleString();
            case "poundsPerDay":
              return Number(this.subjob.job.item.poundsPerDay).toLocaleString();
            case "poundsNeeded":
              return Number(this.poundsNeeded).toLocaleString();
            case "readyProdDate":
              if (this.itemSchedule.readyProdDate != null) {
                return Util.getDateString(this.itemSchedule.readyProdDate);
              } else {
                return "";
              }
            default:
              return "";
          }
        }

        getJson(columns) {
          var json = {};
          for (var i = 0; i < columns.length; i++) {
            json[columns[i].key] = this.getValueFromKey(columns[i].key);
          }

          let dieStatus = "";

          switch (this.itemSchedule.dieOrderStock) {
            case true:
            case "true":
            case "ordered":
              dieStatus = "ORDER";
              break;
            case "received":
              dieStatus = "REÇU";
              break;
            case "inStock":
              dieStatus = "STOCK";
              break;
            default:
              break;
          }

          json.job = this.subjob.title;
          json.mold = this.subjob.job.item.mold;
          json.client = this.subjob.job.item.client.name;
          json.totalQuantity = this.quantity;
          json.remainingQuantity = this.remainingQuantity;
          json.producedQuantity = this.producedQuantity;
          json.sheetsPerHour = this.sheetsPerHour;
          json.hoursAmount = this.hoursAmount;
          json.start = this.startDate;
          json.end = this.endDate;
          json.materialReady = this.materialReady ? "YES" : "NO";
          json.requiresOvertime = this.requiresOvertime ? "YES" : "NO";
          json.moldOut = this.itemSchedule.moldOut ? "YES" : "NO";
          json.recycleMold = this.itemSchedule.recycleMold ? "REPRISE" : "NEW";
          json.dieVerified = this.itemSchedule.dieVerified ? "YES" : "NO";
          json.mMachine = this.itemSchedule.mMachine ? "YES" : "NO";
          json.fFinition = this.itemSchedule.fFinition ? "YES" : "NO";
          json.comment = this.itemSchedule.comment;
          json.dieOrderStock = dieStatus;
          json.readyProd = this.itemSchedule.readyProd ? "PRÊT" : "";
          json.sheetVerification = this.itemSchedule.sheetVerified
            ? "YES"
            : "NO";
          json.mechanic = this.itemSchedule.mechanic;
          if (this.itemSchedule.startDate) {
            json.itemStart = this.itemSchedule.startDate;
          }
          if (this.itemSchedule.endDate) {
            json.endDate = this.itemSchedule.endDate;
          }
          if (this.itemSchedule.setupEndDate) {
            json.setupEndDate = this.itemSchedule.setupEndDate;
          }
          if (this.itemSchedule.readyProdDate) {
            json.readyProdDate = this.itemSchedule.readyProdDate;
          }
          return json;
        }

        matchesSearchString(searchString) {
          const lowerCasedSearchString = searchString.toLowerCase();

          if (lowerCasedSearchString === "") {
            return true;
          }

          if (
            this.subjob.title.toLowerCase().indexOf(lowerCasedSearchString) > -1
          ) {
            return true;
          }
          if (
            this.subjob.job.item.mold
              .toLowerCase()
              .indexOf(lowerCasedSearchString) > -1
          ) {
            return true;
          }
          if (
            this.subjob.job.item.client.name
              .toLowerCase()
              .indexOf(lowerCasedSearchString) > -1
          ) {
            return true;
          }
          if (
            this.getValueFromKey("item")
              .toLowerCase()
              .indexOf(lowerCasedSearchString) > -1
          ) {
            return true;
          }
          if (
            this.getValueFromKey("material")
              .toLowerCase()
              .indexOf(lowerCasedSearchString) > -1
          ) {
            return true;
          }
          if (
            this.getValueFromKey("salesman")
              .toLowerCase()
              .indexOf(lowerCasedSearchString) > -1
          ) {
            return true;
          }

          return false;
        }
      }

      return SubjobSchedule;
    }
  );
